import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, SecurityContext } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MarkdownModule, MarkdownModuleConfig, SECURITY_CONTEXT } from 'ngx-markdown';
import { MarkdownEditorComponent } from './component/markdown-editor.component';

export interface MarkdownEditorModuleConfig {
  previewConfig?: Omit<MarkdownModuleConfig, 'loader'>;
}

@NgModule({
  declarations: [MarkdownEditorComponent],
  imports: [
    CommonModule,
    // BrowserModule,
    // BrowserAnimationsModule,
    // HttpClientModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MarkdownModule.forRoot(),
  ],
  exports: [MarkdownEditorComponent],
})
export class MdefyMarkdownEditorModule {
  static forRoot(config?: MarkdownEditorModuleConfig): ModuleWithProviders<MdefyMarkdownEditorModule> {
    return {
      ngModule: MdefyMarkdownEditorModule,
      providers: [
        config?.previewConfig?.markedOptions || [],
        {
          provide: SECURITY_CONTEXT,
          useValue: config?.previewConfig?.sanitize ?? SecurityContext.HTML,
        },
      ],
    };
  }
  static forChild(): ModuleWithProviders<MdefyMarkdownEditorModule> {
    return {
      ngModule: MdefyMarkdownEditorModule,
      providers: [
        {
          provide: SECURITY_CONTEXT,
          // turning of sanitizing since the html is generated within web app and as it affects
          // rendering uploaded images within the markdown
          useValue: SecurityContext.NONE,
        },
      ],
    };
  }
}
