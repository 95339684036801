<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()"
       href="#"
       class="sidebar-toggle">
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>
    <a class="logo"
       href="#"
       (click)="navigateHome()">ගවේෂ Console</a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
  <ngx-layout-direction-switcher class="direction-switcher"></ngx-layout-direction-switcher> -->
</div>

<div class="header-container">
  <nb-actions size="small">

    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <!-- <nb-action class="control-item" icon="email-outline" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true"></nb-action> -->
    <nb-action class="control-item"
               icon="email-outline"></nb-action>
    <nb-action class="control-item"
               icon="bell-outline"></nb-action>
    <nb-action class="user-action"
               *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu"
               nbContextMenuTag="user-menu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
